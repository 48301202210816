import { Box, Text } from "@cloudline/cloudline-styled-components";
import { useRouter } from "next/router";
import { useAuthError } from "../hooks/useAuthError";
import { AuthRoutePaths } from "../types";

type AuthLinkObject = {
  subtitle: string;
  linkText: string;
  link: string;
};

// Function that returns an object to display correct auth route link.
function renderAuthRouteLink(authRoute: string): AuthLinkObject {
  if (authRoute === AuthRoutePaths.Login) {
    return {
      subtitle: "Don't have an account?",
      linkText: "Sign Up",
      link: AuthRoutePaths.SignUp
    };
  }

  return {
    subtitle: "Already have an account?",
    linkText: "Login",
    link: AuthRoutePaths.Login
  };
}

/**
 * A component rendered in the Authlanding which allows the visitor
 * to switch between the signup and login pages.
 */
export const AuthRouteLinkText = () => {
  const { push, pathname } = useRouter();
  const clearError = useAuthError(state => state.clearError);
  const { subtitle, link, linkText } = renderAuthRouteLink(pathname);

  const handleLinkClick = () => {
    clearError();
    push(link);
  };

  return (
    <Box flex justify="center" mt={4} gap={2}>
      <Text size={16} color="grey.600">
        {subtitle}
      </Text>
      <Box onClick={handleLinkClick}>
        <Text as="span" color="primary.500" weight={600} size={16}>
          {linkText}
        </Text>
      </Box>
    </Box>
  );
};
