import { useSite } from "features/site/hooks/useSite";
import { useRedirectLink } from "hooks/useRedirectLink";
import { useRouter } from "next/router";

/**
 * Hook used to store a URL wherever a user needs to be authenticated
 * and then directs to it once the user has signed up or logged in.
 */
export const useRedirectURL = () => {
  const { data: { authRequired } = {} } = useSite();
  const { replace, asPath } = useRouter();
  const { redirectLink, setRedirectLink, removeLink } = useRedirectLink();

  /** Set the direct URL when redirected to sign up/login. */
  function setRedirectURL() {
    if (authRequired) return;
    setRedirectLink(asPath);
  }

  /** Redirect to a URL after sign up/login */
  function redirectToURL() {
    // If auth is required, the redirectLink would have never been set so it will direct to "/".
    replace(redirectLink || "/").then(removeLink);
  }

  return { setRedirectURL, redirectToURL };
};
