import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import { useRedirectURL } from "features/auth/hooks/useRedirectURL";
import OneSignal from "react-onesignal";
import { CognitoUser, SignInValues } from "../types";
import { useAuthError } from "./useAuthError";
import { useSignUpState } from "./useSignUpState";

const signIn = async ({
  email,
  password
}: SignInValues): Promise<CognitoUser> => {
  const user = await Auth.signIn(email.trim().toLowerCase(), password);
  const cognitoGroups =
    user.signInUserSession.accessToken.payload["cognito:groups"];

  /** If a user logs into their Operator account they wont have a visitor doc so need to log them out and inform them. */
  if (cognitoGroups?.includes("Operators")) {
    await Auth.signOut();

    throw new Error(
      "This account belongs to an Operator. Please use a different email address when using the visitor application."
    );
  }

  return user;
};

/** Hook to sign in a user */
export const useSignIn = () => {
  const queryClient = useQueryClient();
  const clearError = useAuthError(state => state.clearError);
  const clearSignUpState = useSignUpState(state => state.clear);
  const { redirectToURL } = useRedirectURL();

  return useMutation<CognitoUser, Error, SignInValues, void>({
    mutationFn: (values: SignInValues) => signIn(values),
    onMutate: async () => queryClient.removeQueries(["visitor"]),
    onSuccess: ({ username }) => {
      clearSignUpState();
      clearError();
      redirectToURL();
      OneSignal.login(username || "");
    },
    onError: err => {
      console.error("sign in error:", err);
      useAuthError.setState({ error: err?.message });
    }
  });
};
