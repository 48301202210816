import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface State {
  refID: string;
  email: string;
  phone_number: string;
  userSub: string;
  clear: () => void;
}

const initialState = {
  refID: "",
  email: "",
  phone_number: "",
  userSub: ""
};

export const useSignUpState = create<State>()(
  persist(
    set => ({
      ...initialState,
      clear: () => set(initialState)
    }),
    {
      name: "signUpState",
      storage: createJSONStorage(() => localStorage)
    }
  )
);
