import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import {
  Box,
  Button,
  Flex,
  Form,
  Show,
  StandardFormField,
  Text,
  theme
} from "@cloudline/cloudline-styled-components";
import { Alert } from "components/Alert";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
// import Facebook from "static/facebook.png";
import Google from "static/google.png";
import styled from "styled-components";
import { signInSchema } from "utils/formValidation";
import { useAuthError } from "../hooks/useAuthError";
import { useSignIn } from "../hooks/useSignIn";
import { AuthRoutePaths } from "../types";
import { animation } from "../utils/motionAnimation";
import { AuthRouteLinkText } from "./AuthRouteLinkText";
import { SocialButton } from "./SocialButton";
import { Terms } from "./Terms";

const ForgotPassword = styled.button`
  margin-top: 8px;
  background: none;
  font-size: 12px;
  z-index: 100;
  width: fit-content;
  text-align: end;
  color: ${theme.color.grey[900]};
`;

/**
 * Login form which renders out fields for email and password,
 * social login buttons, and a submit button.
 * @returns JSX.Element
 */
export const Login = () => {
  const { push, query } = useRouter();
  const { mutate: signIn, isLoading, error, isSuccess } = useSignIn();
  const authError = useAuthError(state => state.error);

  const passwordReset = query.passwordReset as string;

  return (
    <Box as={motion.div} {...animation} w="100%">
      <Form
        defaultValues={{ email: "", password: "" }}
        validation={signInSchema}
        onSubmit={signIn}
      >
        <Flex column gap={3}>
          <Show when={passwordReset}>
            <Box my={2}>
              <Alert message="Password successfully reset" type="success" />
            </Box>
          </Show>
          <StandardFormField
            elType="input"
            type="email"
            name="email"
            placeholder="Email Address"
          />
          <StandardFormField
            elType="input"
            type="password"
            name="password"
            placeholder="Password"
          />

          <Button
            type="submit"
            size="lg"
            fullWidth
            variant="filled"
            value="Log In"
            style={{
              fontWeight: 600,
              letterSpacing: "0px",
              marginTop: "0.5rem"
            }}
            isLoading={isLoading || isSuccess}
            disabled={isLoading || isSuccess}
            lowercase
          />

          <Flex justify="flex-end">
            <ForgotPassword
              type="button"
              onClick={() => push(AuthRoutePaths.ResetPasswordEmailEntry)}
            >
              Forgot Password?
            </ForgotPassword>
          </Flex>

          <Text color="error.500" size={14} align="center" weight={500}>
            {error?.message || authError}
          </Text>

          <SocialButton
            text="Log in with Google"
            provider={CognitoHostedUIIdentityProvider.Google}
            imageSrc={Google.src}
            logoWidth={17}
            logoHeight={17}
          />
          {/* <SocialButton
            text="Log in with Facebook"
            provider={CognitoHostedUIIdentityProvider.Facebook}
            imageSrc={Facebook.src}
            logoWidth={20}
            logoHeight={20}
          /> */}
        </Flex>
      </Form>

      <AuthRouteLinkText />

      <Terms />
    </Box>
  );
};
