import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import Image from "next/image";
import styled from "styled-components";
import { useAuthError } from "../hooks/useAuthError";
import { useSocialSignIn } from "../hooks/useSocialSignIn";

const Social = styled.button`
  height: 44px;
  border: 1px solid #d8d8d8;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 8px;
  }
`;

interface Props {
  text: string;
  provider: CognitoHostedUIIdentityProvider;
  imageSrc: string;
  logoWidth: number;
  logoHeight: number;
}

/**
 * Rendering out social login button for Google and Facebook.
 * @param text string
 * @param provider CognitoHostedUIIdentityProvider
 * @param imageSrc string
 * @param logoWidth number
 */
export const SocialButton = ({
  text,
  provider,
  imageSrc,
  logoWidth,
  logoHeight
}: Props) => {
  const { mutate: signIn } = useSocialSignIn();
  const clearError = useAuthError(state => state.clearError);

  const handleClick = () => {
    clearError();
    signIn(provider);
  };

  return (
    <Social type="button" onClick={handleClick}>
      <Image src={imageSrc} alt={text} width={logoWidth} height={logoHeight} />
      <span>{text}</span>
    </Social>
  );
};
