import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface State {
  redirectLink: string;
  setRedirectLink: (redirectLink: string) => void;
  removeLink: () => void;
}

/** Store the redirect link in session storage for redirecting after sign up. */
export const useRedirectLink = create<State>()(
  persist(
    set => ({
      redirectLink: "",
      setRedirectLink: (redirectLink: string) => set({ redirectLink }),
      removeLink: () => set({ redirectLink: "" })
    }),
    {
      name: "redirect-link",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
