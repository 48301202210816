import { Center, theme } from "@cloudline/cloudline-styled-components";
import { IoWarningOutline } from "react-icons/io5";
import { MdErrorOutline, MdInfoOutline } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import styled from "styled-components";

const Container = styled.div<{ borderColor: string; background: string }>`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 12px;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 5px;
  background: ${({ background }) => background};
  font-weight: 400;
  color: ${theme.color.grey[900]};
`;

interface Props {
  message: string | JSX.Element;
  type?: "error" | "info" | "success" | "warning";
}

const alerts = {
  error: {
    color: theme.color.error[500],
    background: theme.color.error[100],
    icon: <MdErrorOutline color={theme.color.error[500]} size="20px" />
  },
  info: {
    color: "#ae6eff",
    background: "#f6f0ff",
    icon: <MdInfoOutline color="#ae6eff" size="20px" />
  },
  success: {
    color: theme.color.success[200],
    background: "#D3F5D3", // theme.color.success[100] isn't light enough.
    icon: <TiTick color={theme.color.success[400]} size="20px" />
  },
  warning: {
    color: theme.color.warning[500],
    background: theme.color.warning[100],
    icon: <IoWarningOutline color={theme.color.warning[500]} size="20px" />
  }
};

/** Component used to render info messages to the user. */
export const Alert = ({ message, type = "info" }: Props) => (
  <Container
    borderColor={alerts[type].color}
    background={alerts[type].background}
    data-testid="alert"
  >
    <Center>{alerts[type].icon}</Center>
    {message}
  </Container>
);
