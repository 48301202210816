import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";
import { Auth } from "aws-amplify";

const signUp = async (provider: CognitoHostedUIIdentityProvider) => {
  Auth.configure({
    oauth: {
      ...Auth.configure().oauth,
      redirectSignIn: `${window.location.origin}/login-success`,
      redirectSignOut: window.location.origin
    }
  });

  await Auth.federatedSignIn({
    provider
  });
};

/** Hook to sign up a user */
export const useSocialSignIn = () => {
  return useMutation<void, Error, CognitoHostedUIIdentityProvider, void>({
    mutationFn: async provider => await signUp(provider),
    onError: console.error
  });
};
