import * as yup from "yup";

/**
 * Basic validation for signing in. Further more detailed
 * validation is done by Amplify.
 */
export const signInSchema = yup.object().shape({
  email: yup.string().required("Please enter an email address"),
  password: yup.string().required("Please enter a password")
});

export const phoneNumberValidation = yup.object().shape({
  phone_number: yup.string().required("Required")
});

export const OTPValidation = yup.object().shape({
  otp: yup
    .string()
    .required("Required")
    .min(6, "Code must be 6 digits long")
    .max(6, "Code must be 6 digits long")
});

export const forgotPWEmailSchema = yup.object().shape({
  email: yup.string().required("Required")
});

export const forgotPWSchemaCode = yup.object().shape({
  newPassword: yup
    .string()
    .required("Required")
    .min(6, "Password must be at least 6 characters."),
  confirmationCode: yup.string().required("Required")
});

export const ageRestrictValidation = yup.object().shape({
  dob: yup
    .date()
    .typeError("Please enter a valid date of birth")
    .required("Please enter a valid date of birth")
});
