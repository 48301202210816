import { Box, Text, theme } from "@cloudline/cloudline-styled-components";
import styled from "styled-components";

const Link = styled.a`
  color: ${theme.color.primary[500]};
`;

type Props = {
  signup?: boolean;
};

export const Terms = ({ signup }: Props) => {
  const text = signup ? "Registering" : "Using our platform";

  return (
    <Box mt={3} px={2}>
      <Text size={12} color="grey.600" align="center">
        {text} means agreeing to our{" "}
        <Link
          href="https://www.cloudlineapp.com/legal/terms-of-service"
          target="_blank"
        >
          terms and conditions
        </Link>{" "}
        and{" "}
        <Link
          href="https://www.cloudlineapp.com/legal/privacy-policy"
          target="_blank"
        >
          privacy policy
        </Link>
        .
      </Text>
    </Box>
  );
};
